import { WebAnalyticsPageEnum, WebAnalyticsTouchPointEnum } from '~/src/models/enums/web-analytics.enum';

export const getScreenName = (pathname: string): WebAnalyticsPageEnum => {
    let screenName;
    switch (pathname) {
        case '/':
            screenName = WebAnalyticsPageEnum.main;
            break;
        default:
            screenName = WebAnalyticsPageEnum.notFound;
    }
    return screenName;
};

export const getTouchPoint = (): WebAnalyticsTouchPointEnum => {
    // @ts-ignore
    const standalone = window.navigator.standalone,
        userAgent = window.navigator.userAgent.toLowerCase(),
        safari = /safari/.test(userAgent),
        ios = /iphone|ipod|ipad/.test(userAgent);

    if (userAgent.includes('wv') || (ios && !standalone && !safari)) {
        // Android, iOS webview
        return WebAnalyticsTouchPointEnum.webview;
    }

    return WebAnalyticsTouchPointEnum.web;
};
