import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsAuthFinished, selectUserProfile } from '~/src/redux/user/user.selectors';
import { GtmService } from '~/src/services/web-analytics.service';
import { getAnalyticsEventData } from '~/src/helpers/web-analytics-events-data.helper';
import { WebAnalyticsActionGroupEnum, WebAnalyticsEventEnum } from '~/src/models/enums/web-analytics.enum';

export const useSendInitAnalytics = () => {
    const isAuthFinished = useSelector(selectIsAuthFinished);
    const userProfile = useSelector(selectUserProfile);

    useEffect(() => {
        isAuthFinished && GtmService.send(
            getAnalyticsEventData({
                event: WebAnalyticsEventEnum.scrn,
                actionGroup: WebAnalyticsActionGroupEnum.nonInteractions,
            }),
        );
    }, [userProfile, isAuthFinished]);
};