export const transliterate = (text: string | undefined | null, toLowerCase?: boolean): string => {
    if (!text) {
        return '';
    }

    const maxLetters = 100;
    const dictionary = [
        { rus: 'а', eng: 'a' },
        { rus: 'б', eng: 'b' },
        { rus: 'в', eng: 'v' },
        { rus: 'г', eng: 'g' },
        { rus: 'д', eng: 'd' },
        { rus: 'е', eng: 'e' },
        { rus: 'ё', eng: 'e' },
        { rus: 'ж', eng: 'zh' },
        { rus: 'з', eng: 'z' },
        { rus: 'и', eng: 'i' },
        { rus: 'й', eng: 'i' },
        { rus: 'к', eng: 'k' },
        { rus: 'л', eng: 'l' },
        { rus: 'м', eng: 'm' },
        { rus: 'н', eng: 'n' },
        { rus: 'о', eng: 'o' },
        { rus: 'п', eng: 'p' },
        { rus: 'р', eng: 'r' },
        { rus: 'с', eng: 's' },
        { rus: 'т', eng: 't' },
        { rus: 'у', eng: 'u' },
        { rus: 'ф', eng: 'f' },
        { rus: 'х', eng: 'h' },
        { rus: 'ц', eng: 'c' },
        { rus: 'ч', eng: 'ch' },
        { rus: 'ш', eng: 'sh' },
        { rus: 'щ', eng: 'sch' },
        { rus: 'ъ', eng: '' },
        { rus: 'ы', eng: 'y' },
        { rus: 'ь', eng: '' },
        { rus: 'э', eng: 'e' },
        { rus: 'ю', eng: 'u' },
        { rus: 'я', eng: 'ya' },
        { rus: ' ', eng: '_' },
    ];

    const array = [...text.toLowerCase()].map((sign) => {
        const a = dictionary.filter((obj) => obj.rus === sign)[0];
        return a ? a?.eng : sign;
    });
    const string = array.slice(0, maxLetters).join('');

    return toLowerCase ? string.toLowerCase() : string;
};
