export enum CounterpartyConnectStatusRUEnum {
    ARCHIVED = 'Архив',
    CONNECTED = 'Подключённые',
    DATA_VALIDATION = 'Проверка данных',
    CAN_CONNECT = 'Можно подключить',
}

export enum CounterpartyConnectStatusEnum {
    ARCHIVED = 'ARCHIVED',
    CONNECTED = 'CONNECTED',
    DATA_VALIDATION = 'DATA_VALIDATION',
    CAN_CONNECT = 'CAN_CONNECT',
}

export enum CounterpartyConnectStatusAllEnum {
    EVERY_CONNECTED = 'EVERY_CONNECTED',
    SOME_CONNECTED = 'SOME_CONNECTED',
    NONE_CONNECTED = 'NONE_CONNECTED',
    DEFAULT = 'DEFAULT',
}
