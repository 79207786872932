import { getScreenName, getTouchPoint } from '~/src/helpers/web-analytics.helper';
import {
    WebAnalyticsPageEnum,
    WebAnalyticsEventEnum,
    WebAnalyticsActionGroupEnum,
    WebAnalyticsEventContentEnum,
    WebAnalyticsEventContextEnum,
    WebAnalyticsTouchPointEnum,
    WebAnalyticsEventCategoryEnum,
    WebAnalyticsEventActionEnum,
    WebAnalyticsButtonLocationEnum,
} from '~/src/models/enums/web-analytics.enum';

export class WebAnalyticsEventDataModel {
    public event: WebAnalyticsEventEnum | null = null;
    public userId: string | null = null;
    public userAuth: 0 | 1 | null = null;
    public actionGroup: WebAnalyticsActionGroupEnum | null = null;
    public screenName: WebAnalyticsPageEnum | string = getScreenName(
        window?.location?.pathname,
    );
    public eventContent: WebAnalyticsEventContentEnum | string | null = null;
    public eventContext: WebAnalyticsEventContextEnum | string | null = null;
    public productName: string | null = null;
    public productID: string | null = null;
    public accountType: string | null = null;
    public touchPoint: WebAnalyticsTouchPointEnum = getTouchPoint();
    public currentTariff: string | null = null;
    public eventValue?: null = null;
    public eventCategory?: WebAnalyticsEventCategoryEnum | string |null = null;
    public eventAction?: WebAnalyticsEventActionEnum | string | null = null;
    public eventLabel?: string | null = null;
    public grclientId?: string;
}

export interface WebAnalyticsEventsCustomDataInterface {
    event?: WebAnalyticsEventEnum;
    eventAction?: WebAnalyticsEventActionEnum;
    eventCategory?: WebAnalyticsEventCategoryEnum | string;
    eventLabel?: string;
    eventContent?: WebAnalyticsEventContentEnum | string | null;
    eventContext?: WebAnalyticsEventContextEnum | string;
    actionGroup?: WebAnalyticsActionGroupEnum;
    accountType?: string;
    productID?: string;
    buttonLocation?: WebAnalyticsButtonLocationEnum;
    currentTariff?: string | null;
    screenName?: WebAnalyticsPageEnum;
    userAuth?: 0 | 1 | null;
    userId?: string | null;
    grclientId?: string;
    productName?: string | null;
}
