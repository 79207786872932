import { isProduction } from '~/src/params';
import { WebAnalyticsEventDataModel } from '~/src/models/web-analytics.model';

export const GtmService = {
    send: (data: WebAnalyticsEventDataModel): void => {
        const pushDataLayer = (data: WebAnalyticsEventDataModel) => {
            if (isProduction) {
                (window.dataLayer || []).push(data);
            } else {
                // eslint-disable-next-line no-console
                console.log('GTM event pushed:', data);
            }
        };

        if (window.ym) {
            pushDataLayer(data);
        } else {
            const interval = setInterval(function () {
                if (window.ym) {
                    pushDataLayer(data);
                    clearInterval(interval);
                } else {
                    // eslint-disable-next-line no-console
                    console.log('Метрика не инициализирована');
                }
            }, 500);
        }
    },
};
