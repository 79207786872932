import { API_HOST_ENV, HOST, isAlphaBetaStands } from '~/src/params';

type getRequestUrlType = {
    defaultUrl: string;
    apiKey: string;
    isSSR?: boolean;
    apiHost?: string;
}

export const getRequestUrlHelper = ({ defaultUrl, apiKey, isSSR, apiHost = API_HOST_ENV }: getRequestUrlType) => {
    return isSSR || isAlphaBetaStands ? `${apiHost}/${defaultUrl}` : `${HOST}/${apiKey}`;
};