import { store } from '~/src/redux/store';
import { WebAnalyticsEventDataModel, WebAnalyticsEventsCustomDataInterface } from '~/src/models/web-analytics.model';
import { transliterate } from '~/src/helpers/transliterate.helper';
import { getTouchPoint } from '~/src/helpers/web-analytics.helper';

export const getAnalyticsEventData = (
    options?: WebAnalyticsEventsCustomDataInterface,
): WebAnalyticsEventDataModel => {
    const {
        idToken: { isAuth },
        user: userState,
        referralLead: { referralCode, referralProductsUrl },
    } = store.getState();
    const user = userState.userProfile;
    const base = new WebAnalyticsEventDataModel();
    const enhance = (): WebAnalyticsEventDataModel =>
        ({
            ...base,
            userAuth: isAuth ? 1 : 0,
            userId: user.id ?? null,
            currentTariff: user.currentTariff ? transliterate(user?.currentTariff) : null,
            touchPoint: getTouchPoint(),
            buttonLocation: null,
            accountType: user.accountType ? transliterate(user.accountType) : null,
            screenName: window?.location?.pathname,
            productName: transliterate(referralProductsUrl) || null,
            ...(referralCode ? { grclientId: referralCode } : {}),
            ...options,
        });

    return enhance();
};
