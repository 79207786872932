import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAuthFinished, selectOrganizations } from '~/src/redux/user/user.selectors';
import { selectIsAuth } from '~/src/redux/id-token/id-token.selectors';
import { setOrganizationsAction, setOrganizationsLoading } from '~/src/redux/user/user.slice';
import { OrganizationsService } from '~/src/services/organizations.service';

export const useOrganizationsDispatch = () => {
    const isAuthFinished = useSelector(selectIsAuthFinished);
    const isAuth = useSelector(selectIsAuth);
    const organizationsFromStore = useSelector(selectOrganizations);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isAuthFinished && isAuth && !organizationsFromStore.length) {
            dispatch(setOrganizationsLoading(true));
            OrganizationsService.getOrganizations()
                .then((res) => {
                    dispatch(setOrganizationsAction(res?.data || []));
                })
                .finally(() => dispatch(setOrganizationsLoading(false)));
        }
    }, [isAuth, isAuthFinished, organizationsFromStore.length]);
};
