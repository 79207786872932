import {
    getAutocompleteOrganization,
    getOrganizationAddress,
    getOrganizations,
} from '~/src/repositories/organizations/organizations.repository';

export const OrganizationsService = {
    getOrganizations,
    getAutocompleteOrganization,
    getOrganizationAddress,
};
