import type { RootState } from '~/src/redux/store';
import type { SuggestedOrganizationType } from '~/src/components/blocks/clients-request/organization-info/organization-info.types';
import type { LegalFormEnum } from '~/src/models/enums/legal-form.enum';
import type { LeadsReportStateEnum } from '~/src/models/enums/leads-report-status.enum';

export const selectSelectedOrganizationPartyIdLead = (state: RootState): string =>
    state.lead.selectedOrganizationPartyIdLead;

export const selectCountCheckedProducts = (state: RootState): number => state.lead.countCheckedProducts;

export const selectSuggestedOrganization = (state: RootState): SuggestedOrganizationType =>
    state.lead.suggestedOrganization;

export const selectLegalForm = (state: RootState): LegalFormEnum => state.lead.legalForm;

export const selectIsRequiredAddressField = (state: RootState): boolean => state.lead.isRequiredAddressField;

export const selectIsShowAddressRequiredPlank = (state: RootState): boolean => state.lead.isShowAddressRequiredPlank;

export const selectCreatedLeadId = (state: RootState): number => state.lead.createdLeadId;

export const selectLeadsReportState = (state: RootState): LeadsReportStateEnum => state.lead.leadsReportState;

export const selectLeadsReportStatusProgress = (state: RootState) => state.lead.leadsReportStatusProgress;
