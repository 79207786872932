
require("./../../node_modules/.pnpm/@module-federation+nextjs-mf@6.5.1_next@13.3.4_react-dom@18.2.0_react@18.2.0_webpack@5.87.0/node_modules/@module-federation/nextjs-mf/src/include-defaults.js");
import React, { useEffect } from 'react';
import { GetServerSideProps, NextPage } from 'next';
import dynamic from 'next/dynamic';
import { useDispatch } from 'react-redux';
import { ProductsService } from '~/src/services/products.service';
import { OfferService } from '~/src/services/offer.service';
import { setReferralCode } from '~/src/redux/referral-lead/referral-lead.slice';
import getServerSidePropsCommon from '~/src/lib/serverProps';
import { useOrganizationsDispatch } from '~/src/hooks/use-organizations-dispatch.hook';
import { useSendInitAnalytics } from '~/src/hooks/use-send-init-analytics.hook';
import type { ProductsListItemInterface } from '~/src/repositories/products/products.repository';

export interface HomePageProps {
    productsData: ProductsListItemInterface[] | [];
    offerUrl: string;
    agentId: string | string[];
}

const MainLandingLayout = dynamic(() => import('~/src/layouts/main-landing-layout/main-landing-layout'));

const HomePage: NextPage<HomePageProps> = ({ productsData, offerUrl, agentId }) => {
    const dispatch = useDispatch();
    useOrganizationsDispatch();
    useSendInitAnalytics();

    useEffect(() => {
        if (agentId && typeof agentId === 'string') {
            dispatch(setReferralCode(agentId?.replace(/(")/g, '')));
        }
    }, [agentId]);

    return (
        <>
            <MainLandingLayout productsData={productsData} offerUrl={offerUrl} />
        </>
    );
};

export const getServerSideProps: GetServerSideProps = async ({ query }) => {
    const agentId = query?.agentId;
    const productsData = await ProductsService.getProductsList(true);
    const { url: offerUrl = '' } = await OfferService.getOffer(true);
    return {
        props: {
            ...(await getServerSidePropsCommon()),
            productsData: productsData || [],
            offerUrl,
            agentId: agentId || '',
        },
    };
};

export default HomePage;
