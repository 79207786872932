export const getConfigSSR = (isSSR?: boolean, cookies?: Partial<{ [key: string]: string; }>, params?: unknown) => {
    const cookie = Object.entries(cookies || {}).reduce(
        (acc, [key, value]) => acc + `${key}=${value}; `,
        '',
    );
    if (isSSR) {
        return { headers: { cookie }, params };
    }

    return { params };
};