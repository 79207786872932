import { createSelector } from 'reselect';
import { UserProfile, UserState } from '~/src/redux/user/user.types';
import { checkStatusConnected } from '~/src/helpers/connected-status.helper';
import { selectIsAuth } from '~/src/redux/id-token/id-token.selectors';
import { selectSelectedOrganizationPartyIdLead } from '~/src/redux/lead/lead.selectors';
import { PrivilegesEnum } from '~/src/models/enums/privileges.enum';
import type { OrganizationInterface } from '~/src/repositories/organizations/types/organizations.types';
import type { RootState } from '~/src/redux/store';
import type { StatusPrivilegesInterface } from '~/src/repositories/user/user.repository';

export const selectUserProfile = (state: RootState): UserProfile => state.user.userProfile;

export const selectUserProfileIsUpdating = (state: RootState) => state.user.userProfile.isUpdating;

export const selectIsAuthFinished = (state: RootState) => state.user.isAuthFinished;

export const selectPrivilegesData = (state: RootState) => state.user.privilegesData;

export const selectUserState = (state: RootState): UserState => state.user;

export const selectWorkingPartyId = (state: RootState): UserState['workingPartyId'] => state.user.workingPartyId;

export const selectUserPartyId = (state: RootState) => state.user.userPartyId;

export const selectOrganizations = (state: RootState): OrganizationInterface[] | [] => state.user.organizations;

export const selectOrganizationsLoading = (state: RootState) => state.user.organizationsIsLoading;

export const selectAgentId = (state: RootState) => state.user.agentId;

export const selectIsConnectedCurrentOrganizationInHeader = createSelector(
    [selectOrganizations, selectWorkingPartyId],
    (organizations: OrganizationInterface[] | [], workingPartyId: string): boolean => {
        if (!organizations.length || !workingPartyId) {
            return false;
        }
        const [currentOrganizationInHeader] = organizations.filter((elem) => elem.partyId === workingPartyId);
        return checkStatusConnected(currentOrganizationInHeader);
    },
);

export const selectCurrentOrganization = createSelector(
    [selectOrganizations, selectWorkingPartyId],
    (organizations: OrganizationInterface[] | [] | null, workingPartyId: string):
        OrganizationInterface | Record<string, never> => {
        if (organizations?.length) {
            const [currentOrganization] = organizations.filter((elem) => elem.partyId === workingPartyId);
            return currentOrganization || {};
        }
        return {};
    },
);

export const selectCurrentOrganizationConnectionStatus = createSelector(
    selectCurrentOrganization,
    (currentOrganization) => currentOrganization?.connectingStatus
);

export const selectIsConnectedCurrentOrganizationForButton = createSelector(
    [selectIsConnectedCurrentOrganizationInHeader, selectUserState, selectIsAuth],
    (isConnectedCurrentOrganizationInHeader: boolean, user: RootState['user'], isAuth): boolean | null => {
        const { isAuthFinished, workingPartyId } = user;

        if (workingPartyId) {
            return isConnectedCurrentOrganizationInHeader;
        }

        if (isAuthFinished && !isAuth) {
            return false;
        }

        return null;
    },
);

export const selectCurrentOrganizationIsByPrivileged = createSelector(
    [selectPrivilegesData, selectSelectedOrganizationPartyIdLead, (_, privilegeType: PrivilegesEnum) => privilegeType],
    (privilegesData: StatusPrivilegesInterface[] | [], partyIdLead: string, privilegeType): boolean => {
        if (!privilegesData.length || !partyIdLead) {
            return false;
        }

        return privilegesData.some((elem) => {
            return elem.partyId === partyIdLead &&
                elem?.privileges?.hasOwnProperty(privilegeType);
        });
    },
);