import axios, { AxiosResponse } from 'axios';
import { captureException } from '@sentry/nextjs';
import { getRequestUrlHelper } from '~/src/helpers/get-request-url.helper';
import { getConfigSSR } from '~/src/helpers/cookie-ssr.helper';
import {
    apiKeyAutocompleteOrganizations,
    apiKeyGetAddressOrganization,
    apiKeyGetOrganization,
    defaultUrlAutocompleteOrganizations,
    defaultUrlGetAddressOrganization,
    defaultUrlGetOrganization,
} from '~/src/repositories/organizations/api-urls';
import type {
    AutocompleteOrganizationInterface,
    OrganizationInterface,
} from '~/src/repositories/organizations/types/organizations.types';
import type {
    AddressSuggestionProps,
} from '~/src/components/common-components/dropdown-address-item/dropdown-address-item.types';

export const getOrganizations = async (isSSR?: boolean, cookies?: Partial<{ [key: string]: string }>):
    Promise<AxiosResponse<OrganizationInterface[] | []> | undefined> => {
    const url = getRequestUrlHelper({ isSSR, apiKey: apiKeyGetOrganization, defaultUrl: defaultUrlGetOrganization });

    try {
        return await axios.get<OrganizationInterface[] | []>(url, getConfigSSR(isSSR, cookies));
    } catch (error: unknown) {
        // eslint-disable-next-line no-console
        console.log(error);
        captureException(error);
    }
};

export const getAutocompleteOrganization = async (searchQuery: string, isSSR?: boolean):
    Promise<AutocompleteOrganizationInterface[] | []> => {
    const url = getRequestUrlHelper({
        isSSR,
        apiKey: apiKeyAutocompleteOrganizations,
        defaultUrl: defaultUrlAutocompleteOrganizations,
    });

    const config = { params: { searchQuery } };
    const { data } = await axios.get<{ suggestions: AutocompleteOrganizationInterface[] | null }>(url, config);
    return data.suggestions || [];
};

export const getOrganizationAddress = async (
    searchString: string,
    isSSR?: boolean,
    cookies?: Partial<{ [key: string]: string }>,
):
    Promise<AddressSuggestionProps[] | []> => {
    const url = getRequestUrlHelper({
        isSSR,
        apiKey: apiKeyGetAddressOrganization,
        defaultUrl: defaultUrlGetAddressOrganization,
    });

    const params = { searchString };
    const { data } = await axios.get<AddressSuggestionProps[] | []>(url, getConfigSSR(isSSR, cookies, params));
    return data || [];
};